import React, { useCallback, useState } from 'react';
import photo from '../../../assets/photo.svg';
import cancelIcon from '../../../assets/cancel.svg';

const ProductImageUpload = ({
  label = "Product Image",
  name = "image",
  imageSrc,
  imageName,
  onFileChange,
  onRemove,
  error
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const validateFile = (file) => {
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    const maxSize = 1024 * 1024; // 1MB

    if (!validTypes.includes(file.type)) {
      alert('Please upload PNG, JPG, JPEG, or WEBP files only');
      return false;
    }

    if (file.size > maxSize) {
      alert('File size should be less than 1MB');
      return false;
    }

    return true;
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    console.log(file);
    if (!file) return;

    if (validateFile(file)) {
      const event = {
        target: {
          files: [file],
          name: name
        }
      };
      onFileChange(event);
    }
  }, [onFileChange]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (validateFile(file)) {
      onFileChange(e);
    }
  };

  return (
    <div className="col-span-full">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div
        className={`mt-2 flex justify-center rounded-lg border-2 border-dashed px-6 py-4 relative transition-all duration-200
          ${isDragging 
            ? 'border-indigo-600 bg-indigo-50' 
            : 'border-gray-900/25 hover:bg-gray-50'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!imageSrc ? (
          <div className="text-center">
            <img 
              src={photo} 
              alt="photo" 
              aria-hidden="true" 
              className="mx-auto h-10 w-10" 
            />
            <div className="mt-2 flex flex-col text-sm leading-6 text-gray-600">
              <label htmlFor={name} className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600">
                <span className="text-center">Upload a file</span>
                <input
                  id={name}
                  name={name}
                  type="file"
                  className="sr-only"
                  onChange={handleFileChange}
                  accept="image/png,image/jpeg,image/jpg,image/webp"
                />
              </label>
              <p className="text-xs text-gray-600 mt-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600 mt-1">PNG, JPG, JPEG, WEBP up to 1MB</p>
          </div>
        ) : (
          <div className="w-full">
            <div className="px-3 right-0 absolute">
              <img
                src={cancelIcon}
                alt="cancel-icon"
                className="w-5 cursor-pointer"
                title="Remove"
                onClick={onRemove}
              />
            </div>
            <div className="text-center">
              <img 
                src={imageSrc} 
                alt="uploaded preview" 
                className="mx-auto max-h-24 max-w-24 object-contain" 
              />
              <p className="text-xs leading-5 text-gray-600 mt-2">
                File name: {imageName}
              </p>
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-red text-sm mt-1">{error}</p>}
    </div>
  );
};

export default ProductImageUpload;
